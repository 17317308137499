import React from "react";

export const Vision = (props) => {
  return (
    <div id="Vision" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Vision & Mission</h2>
          <p>
            
Building a Future of Unity, Integrity, and Empowerment

Shanthkumar Jettur’s vision is one of unity, integrity, and inclusive development. He believes in a future where every individual has the opportunity to contribute to the nation’s progress, regardless of their background. His mission is to continue advocating for social justice, empowering marginalized communities, and strengthening democratic values.

Shanthkumar is dedicated to mentoring the next generation of leaders, instilling in them the values of service, dedication, and patriotism. He envisions a political landscape where leaders are guided by principles rather than power, and where the welfare of the people is the foremost priority.

As he looks to the future, Shanthkumar remains committed to fostering a sense of national pride and unity among citizens, working towards a society that is just, equitable, and prosperous for all.
          </p>
        </div>
        {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
      </div>
    </div>
  );
};
